import * as m from "mithril";

const {p,a } = require("hyperscript-helpers")(m);


const a240604 = {
    title: "Was gibt es in Mainz zu sehen?",
    date: "20.September 2024",
    id: "240920",
    shortext: [
        p("Die beste Auszeit für Zapfika ist die Exkursion."),
        p("Wir haben jetzt Details zu den Exkursionen."),
    ],
    text: [
        p("Die beste Auszeit für Zapfika ist die Exkursion."),
        p("Die Exkursionen sind eine tolle Möglichkeit, Mainz und die Umgebung kennenzulernen und sich eine Auszeit vom Tagungsprogramm zu gönnen."),
        p("Bitte beachtet, dass für einige Exkursionen ein Unkostenbeitrag anfällt. Die genauen Beträge findet ihr in der Beschreibung der jeweiligen Exkursion."),
        a({href: "#!/programm"},"Hier findet ihr eine Übersicht über die Exkursionen, die wir für euch geplant haben"),
        
    ]
};

export default a240604;
