import * as m from "mithril";

const {p} = require("hyperscript-helpers")(m);


const a240604 = {
    title: "Ihr könnt endlich packen",
    date: "10.August 2024",
    id: "240810",
    shortext: [
        p("Wir haben endlich eine Packliste für euch!"),
        p("Jetzt könnt ihr eure Koffer packen und euch auf den Weg zur ZaPF machen."),
    ],
    text: [
        p("Wir haben endlich eine Packliste für euch!"),
        p("Jetzt könnt ihr eure Koffer packen und euch auf den Weg zur ZaPF machen."),
    ]
};

export default a240604;
