import * as m from "mithril";
import css from "/assets/css/components/navbar.module.css";

const {div, a, img} = require("hyperscript-helpers")(m);


const Navbar: m.Component = {
    view() {
        return [div({class: css.bar, id: css.navbar,onclick: (event: Event) => {
            event.stopPropagation();
            document.getElementById(css.tagungsbar).style.display = 'none'
        }}, [
            a({href: "#!", class: css.logo,}, img({src: require('/assets/img/logo.svg')})),
            div({class: css.links}, [
                a({href: "#!/news", class: css.link}, "News"),
                a({href: "#!/about", class: css.link}, "Über uns"),
                a({href: "#!/zapf", class: css.link}, "Die ZaPF"),

                div({
                        class: css.link,
                        oncreate: () => {
                            document.getElementById(css.tagungsbar).style.display = 'none'
                        },
                        onclick: (event: Event) => {
                            event.stopPropagation();
                            if(document.getElementById(css.tagungsbar).style.display == 'flex'){
                                document.getElementById(css.tagungsbar).style.display = 'none'
                            }else {
                                document.getElementById(css.tagungsbar).style.display = 'flex'
                            }
                        },
                        onmouseover: (event: Event) => {
                            event.stopPropagation();
                            document.getElementById(css.tagungsbar).style.display = 'flex'
                        },
                        onmouseout: (event: Event) => {
                            event.stopPropagation();
                            document.getElementById(css.tagungsbar).style.display = 'none'
                        }
                    },
                    "Zur Tagung"),

                    div({
                            class: css.link,
                            oncreate: () => {
                                document.getElementById(css.vorderzapfbar).style.display = 'none'
                            },
                            onclick: (event: Event) => {
                                event.stopPropagation();
                                if(document.getElementById(css.vorderzapfbar).style.display == 'flex'){
                                    document.getElementById(css.vorderzapfbar).style.display = 'none'
                                }else {
                                    document.getElementById(css.vorderzapfbar).style.display = 'flex'
                                }
                            },
                            onmouseover: (event: Event) => {
                                event.stopPropagation();
                                document.getElementById(css.vorderzapfbar).style.display = 'flex'
                            },
                            onmouseout: (event: Event) => {
                                event.stopPropagation();
                                document.getElementById(css.vorderzapfbar).style.display = 'none'
                            }
                        },
                        "Vor der ZaPF"),
            ])
        ]), div({
            class: css.bar, id: css.tagungsbar,
            onmouseover: (event: Event) => {
                event.stopPropagation();
                document.getElementById(css.tagungsbar).style.display = 'flex'
            },
            onmouseout: (event: Event) => {
                event.stopPropagation();
                document.getElementById(css.tagungsbar).style.display = 'none'
            }
        }, [
            a({href: "#!/anreise", class: css.link}, "Anreise"),
            a({href: "#!/programm", class: css.link}, "Programm"),
            a({href: "#!/merch", class: css.link}, "Merch"),
        ]),
        div({
            class: css.bar, id: css.vorderzapfbar,
            onmouseover: (event: Event) => {
                event.stopPropagation();
                document.getElementById(css.vorderzapfbar).style.display = 'flex'
            },
            onmouseout: (event: Event) => {
                event.stopPropagation();
                document.getElementById(css.vorderzapfbar).style.display = 'none'
            }
        }, [
            a({href: "https://anmeldung.zapf.in", class: css.link}, "Anmeldung"),
            a({href: "#!/packliste", class: css.link}, "Packliste"),
        ]),
        div({class: css.bar, id: css.linkbar}, [
            a({href: "#!/awareness", class: css.link}, "Vertrauenspersonen"),
            a({href: "https://app.zapf.in", class: css.link}, "App"),
            a({href: "#!/help", class: css.link}, "Mithelfen"),
        ])];
    },
};

export default Navbar;
