import * as m from "mithril";
import { LeafletMap } from 'mithril-leaflet';
import { Feature, Geometry } from 'geojson';
import {Icon, LatLngExpression, FeatureGroup, LeafletEvent, geoJSON, circleMarker } from 'leaflet'
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, img, br, hr, a, strong} = require("hyperscript-helpers")(m);

delete (Icon.Default.prototype as any)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const pois: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: { name: 'Fachschaft Physik/Meteorologie' },
        geometry: {
          type: 'Point',
          coordinates: [8.233534242047337, 49.990555839908595],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Hauptbahnhof' },
        geometry: {
          type: 'Point',
          coordinates: [8.258702525981628, 50.00112771581561],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Hauptbahnhof Haupteingang' },
        geometry: {
          type: 'Point',
          coordinates: [8.259616176329892, 50.00158117482264],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Hauptbahnhof West' },
        geometry: {
          type: 'Point',
          coordinates: [8.258777526193917, 49.999541033182055],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Friedrich-von-Pfeifer-Weg' },
        geometry: {
          type: 'Point',
          coordinates: [8.236505931846123, 49.99433668895737],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Hegelstraße' },
        geometry: {
          type: 'Point',
          coordinates: [8.234370231628418, 49.99531538621344],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Hauptpforte Campus' },
        geometry: {
          type: 'Point',
          coordinates: [8.2319, 49.9886],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Parkplatz Campus' },
        geometry: {
          type: 'Point',
          coordinates: [8.2340, 49.9895],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Parkplatz Dahlheimer Weg' },
        geometry: {
          type: 'Point',
          coordinates: [8.2363, 49.9887],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Theresianum Mainz' },
        geometry: {
          type: 'Point',
          coordinates: [8.28462936609837, 49.98389482436433],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Shuttle Bus Theresianum' },
        geometry: {
          type: 'Point',
          coordinates: [8.280025156462498, 49.98219960640366],
        },
      },
      {
        type: 'Feature',
        properties: { name: 'Shuttle Bus Uni' },
        geometry: {
          type: 'Point',
          coordinates: [8.232462583268303, 49.98893035864488],
        },
      }
    ]
  };

const toGeoJSON = (g: GeoJSON.FeatureCollection) => {
    const geo = geoJSON(g, {
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          console.log(feature);
          state.feature = feature;
          m.redraw();
        });
        layer.bindPopup(feature.properties.name);
      },
      style: () => ({
        color: '#ff7800',
        weight: 10 * Math.random(),
        opacity: 0.65,
      }),
     //  pointToLayer: (feature, latlng) => circleMarker(latlng, geojsonMarkerOptions),
    });
    return geo;
  };

  const geojsonMarkerOptions = {
    radius: 8,
    fillColor: 'blue',
    color: '#000',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };

const state = {
    visible: ['zapf'],
    count: 0,
    overlays: {
      zapf: toGeoJSON(pois),
    } as { [key: string]: FeatureGroup },
    feature: undefined as Feature<Geometry, any> | undefined,
  }

const page: m.Component = {

    view() {

        const { overlays, visible, feature } = state;
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Anfahrt"),
                m(LeafletMap, {
                    style: 'height: 400px; margin-top: 20px;',
                    view: [49.995, 8.25] as LatLngExpression,
                    zoom: 14,
                    overlays,
                    visible,
                    onMapClicked: console.log,
                    showScale: { imperial: false },
                    onLayerEdited: (f: FeatureGroup) => console.log(JSON.stringify(f.toGeoJSON(), null, 2)),
                    onLoadedOverlaysChanged: (v: string[]) => (state.visible = v),
                }),
                br(),
                h2("Unsere Adresse"),
                p("Fachschaft Physik/Meteorologie", br(), "Staudingerweg 9", br(), "55128 Mainz", br(), "Google Code: X6RM+6CQ Mainz",
                 br(), "49.990, 8.233", br(), "///gerne.durst.soll"),
                h2("Vom HBF zum Campus"),
                p([strong("TLDR:"), br(), "Am Tag", br(), "Haltestelle Hauptbahnhof West -> Friedrich-von-Pfeiffer Weg (2 Haltestellen weit weg)", br(), "Linien 51, 53, 59 (Straßenbahn) oder 54, 55, 56, 58, 650 (Bus)", br(), "Bei Nacht", br(), "Haltestelle Hauptbahnhof oder Hauptbahnhof West -> Universität oder Hegelstraße", br(), "Linie 91 (Bus)"]),
                p([strong("Am Tag:"), " Um vom Hauptbahnhof in Mainz zur Uni zu gelangen, könnt Ihr tagsüber die Linien ",strong("51, 53, 59")," (Straßenbahn) und ",strong("54, 55, 56, 58, 650")," (Bus) nutzen und an der Haltestelle ",strong("Friedrich-von-Pfeiffer Weg – Hochschule für Musik Mainz")," aussteigen. Das sind mit allen Linien 2 Stationen. Steigt NICHT an der Haltestelle Universität aus!", br(), "Natürlich könnt Ihr am Hauptbahnhof panisch zwischen den einzelnen Haltepunkten A bis Q herumrennen und hoffen, die richtige Straßenbahn oder den richtigen Bus zu erwischen (Die richtige Richtung ist – den Bahnhofseingang im Rücken – übrigens nach rechts, da, wo der Daumen links ist.). Wenn Ihr es, insbesondere mit Gepäck, etwas entspannter mögt, verlasst den Hauptbahnhof nicht duch den Haupteingang, sondern in die andere Richtung hinter Gleis 8. Danach ca. 100 Meter immer geradeaus am Parkplatz entlang bis zur Haltestelle " ,strong("Hauptbahnhof West"),", an der alles Nützliche an einem Haltepunkt (B) hält (und dann - den HBF im Rücken - nach rechts abfährt)."]),
                p([strong("Bei Nacht:"), " Solltet Ihr zu später Stunde zum Campus fahren wollen, bringt Euch die Linie ",strong("91")," (Bus) vom Hauptbahnhof (der Bus hält sowohl an der Haltestelle ",strong("Hauptbahnhof West")," (Haltepunkt B) als auch an der Haltestelle ",strong("Hauptbahnhof"),") zur Haltestelle ",strong("Hegelstraße"),". Diese liegt abseits des Campus in einem Wohngebiet. Der Weg zwischen der Physik und der Haltestelle ist aber fast so lang wie der Weg über den Campus zum Forum und der dortigen Haltestelle ",strong("Universität"),", so dass Ihr auch dort aussteigen und unseren Campus erkunden (zur Physik: immer geradeaus) könnt. Die Busse die vom HBF kommen halten am Haltepunkt G, dieser liegt jenseits der Hauptverkehrsstraße."]),
                hr(),
                h2("Auto"),
                p(strong("TLDR:"),br(),"Die Adresse für Euer Navigationsgerät:",br(),"Parkplatz Campus: Ackermannweg, 55128 Mainz",br(),
                 "49.9895, 8.2340",br() ,"///weltreise.frauen.sahne"),
                p("Wenn Ihr mit dem Auto nach Mainz fahrt, kommt Ihr unweigerlich auf den Mainzer Ring. Die Zufahrt zum Campus erfolgt am einfachsten über die BAB 60 und die Ausfahrt 20 (Mainz-Lerchenberg) nach Nordosten auf die Koblenzer Straße. Dieser müsst Ihr anschließend 2,6 km folgen."),
                p([strong("Auf dem Campus:"), " Jedes Kennzeichen (die Uni nutzt eine automatische Kennzeichenerkennung) darf pro Jahr 30 Stunden kostenfrei auf den Campus parken. Ist das Freikontingent erschöpft, wird die Einfahrt verwehrt (und u.U. ein generelles Einfahrverbot verhängt) – vom Campus kommt man aber noch herunter. Wenn Ihr also während der Tagung noch einmal mit dem Auto weg und wieder zurück möchtet, ist Euch das Parken außerhalb empfohlen.",br(),"Die Einfahrt auf den Campus kann nur(!) über die Hauptpforte am Ackermannweg (via Koblenzer Straße erfolgen, parkt dann bitte auf dem Parkplatz direkt südlich des mittleren Kreuzbaus (Staudingerweg 9). Das ist die erste Parkfläche links nach dem ersten Gebäude."]),
                p("Hauptpforte Campus: Ackermannweg, 55128 Mainz 49.9886, 8.2319 ///mehl.arten.maurer"),
                p("Parkplatz Campus: Ackermannweg, 55128 Mainz 49.9895, 8.2340 ///weltreise.frauen.sahne"),
                p([strong("Außerhalb des Campus:"), " Südlich des Campus befindet sich ein großer Parkplatz auf dem Ihr im vorderen Teil dauerhaft kostenfrei parken könnt. Der Parkplatz ist erreichbar, entweder über die Albert-Schweitzer-Straße und den Dahlheimer Weg (ganz nach hinten durchfahren), oder über den Campus (Hauptpforte am Ackermannweg via Koblenzer Straße), dann die erste Möglichkeit rechts durch die Schranken und geradeaus auf dem Parkplatz)."]),
                p("Parkplatz Dahlheimer Weg: Dahlheimer Weg 1, 55128 Mainz X6QP+FH Mainz 49.9887, 8.2363 ///fehlt.wanne.sicher"),
                hr(),
                h2("Zeitmaschine"),
                p("Falls Ihr eine Zeitmaschine besitzt, gebt einfach die Sternzeit 89831,62 ein und begebt euch zu den Koordinaten 49° 59’24.9\" N 8° 14’03.2\"O."),
                hr(),
                h2("Vom Campus zur Unterkunft"),
                p(strong("TLDR:"),br(),
                "Haltestelle Friedrich-von-Pfeiffer Weg -> Martin-Luther-Straße oder Am Viktorstift",br(),
                "Linien 51, 53, 59 (Straßenbahn) oder 54, 55, 56, 58, 650 (Bus)",br(),
                "sowie 64, 65, 66, 76, 93 oder 62,92"),
                p("Unterkommen werdet Ihr in den Räumlichkeiten des Theresianum Mainz. Da es leider keine direkte ÖPNV Verbindung zwischen dem Campus und der Unterkunft gibt, müsst Ihr wenigstens einmal umsteigen. Nemt dazu die Straßenbahn (Linien ",strong("51, 53, 59"),") oder den Bus (Linien ",strong("54, 55, 56, 58, 650"),") von der Haltestelle ",strong("Friedrich-von-Pfeiffer Weg")," zum Hauptbahnhof-West. Den Campus im Rücken fahren die relevanten Fahrzeuge nach rechts ab (Haltebereich A).",br(),"Von dort aus kommt Ihr mit den Linien ",strong("64, 65, 66, 76, 93")," zur Haltestelle ",strong("Martin-Luther-Straße")," oder mit den Linien ",strong("62, 92")," zur Haltestelle ",strong("Am Viktorstift"),". Von beiden Haltestellen aus sind es noch etwa 6 min zu Fuß. Die Linien ",strong("92")," und ",strong("93")," sind dabei Nachtlinien.",br(),"Alternativ könnt Ihr auch die Straßenbahnlinie ",strong("53")," bis zum ",strong("Pariser Tor")," nehmen und etwa 20 min über den Langwehrweg und die Martin-Luther-Straße laufen.",br(),"Egal für welche der Optionen Ihr Euch entscheidet, mit Umstieg und Fußweg solltet Ihr etwa 30 min einplanen."),
                hr(),
                h2("Von der Unterkunft zum Campus"),
                p("Beachtet die Abfahrt der beiden Shuttlebusse von der Haltestelle ",strong("Martin-Luther-Straße"),"."),
                hr(),
                h2("meinRAD"),
                p("Wenn Ihr Mainz mit dem Fahrrad erkunden wollt, könnt Ihr Euch Räder der Mainzer Mobilität leigen (“meinRAD”). Ihr müsst dafür die entsprechende App installieren – mehr unter: ",a({href:"https://www.mainzer-mobilitaet.de/mehr-mobilitaet/meinrad"},"meinRAD-Webseite")),
                hr(),
                h2("Apps und Fahrkarten"),
                p("Solltet Ihr kein 49 € Ticket besitzen, teilt das der Hauptorga mit!",br(),"Beachtet, dass in Bussen und Bahnen der Mainzer Mobilität nur bargeldlos bezahlt werden kann.",br(),"Für Fahrplanauskünfte empfehlen wir den ",a({href:"https://www.bahn.de/service/mobile/db-navigator"},"DB Navigator")," oder die ",a({href:"https://www.mainzer-mobilitaet.de/fahrplaene/app"},"App der Mainzer Mobilität"),),
            ]
        ))
    }
};
export default page;
