import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";
import articles from "../news";

const {div, h1,h2, p,i, img, br, a,hr} = require("hyperscript-helpers")(m);

let news = []
articles.forEach((item) => {
    news.push(
        hr(),
        i({class: style.date},item.date),
        h2(item.title),
        m(m.route.Link, {href: "/news#"+item.id},"Weiterlesen"))
})
const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page}, [
                div({class: style.hero}, [
                    img({src: require('/assets/img/domplatz.png')}),
                    h1("Willkommen zur ZaPF"),
                    h1("im schönen Meenz 🦆"),

                    p("Die ZaPF (Zusammenkunft aller deutschsprachigen Physik-Fachschaften) ist die deutsche Bundesfachschaftstagung für die Physik. Gleichzeitig versteht sich die ZaPF eben auch als die Zusammenkunft aller deutschsprachigen Fachschaften, also gehören auch die Fachschaften aus der Schweiz und Österreich dazu! Als Vertretung zwischen den ZaPFen wählt die ZaPF den StAPF, welcher die ZaPF in der Öffentlichkeit vertritt."),
                    p("Nach über 30 Jahren kommt die ZaPF nach Mainz zurück.\n" +
                        "Direkt am Rhein gelegen, wird die Region hier auch Rheinhessen genannt, auch wenn wir schon in Rheinland-Pfalz liegen, also der richtigen Seite des Rheins...\n" +
                        "Um dem regionalen Dialekt gerecht zu werden, nennen wir die ZaPF ganz nach rhoihessischer Manier: \"ZaPF in Meenz\"."),
                ]),hr(),
                h1("BREAKING NEWS"),
                news,
                hr(),
               h1("Gesponsert von:"), br(),
                div({class: style.sponsor}, [
                    a({href: "https://www.rheinhessen-sparkasse.de"}, img({src: require('/assets/img/Sparkasse.jpg')})),
                    a({href: "https://www.pfeiffer-vacuum.com"}, img({src: require('/assets/img/pfeiffer.jpg')})),
                    a({href: "https://www.highfinesse.com/en/index.html"}, img({src: require('/assets/img/highFinesse.png')})),
                    a({href: "https://www.fz-juelich.de/de"}, img({src: require('/assets/img/juelich.svg')})),
                    a({href: "https://www.sukhamburg.com/"}, img({src: require('/assets/img/suk.svg')})),
                    a({href: "https://www.d-fine.com/karriere/deutschland/uebersicht/"}, img({src: require('/assets/img/dfine.jpg')})),
                    //a({href: "https://www.bmbf.de/bmbf/de/bildung/studium/studium_node.html"}, img({
                    //    src: require('/assets/img/bmbf.jpg'),
                    //   style: "border: solid white 20px"
                    //})),
                ]),br(),
                h1("Gefördert von:"), br(),
                div({class: style.sponsor}, [                    
                    a({href: "https://www.we-heraeus-stiftung.de"}, img({src: require('/assets/img/Heraeus.svg')})),
                    a({href: "https://www.glk.uni-mainz.de"}, img({src: require('/assets/img/GLK.jpg')})),

                ]),
                //TODO Bei Die ZaPF Seite ein Inhaltsverzeichnis (wie bei Anreise in Kiel), ähnlich Kapitel wie Kiel
                //TODO Openstreetmaps bei Anreise (Orte: FS, Bahnhof, Friedrich-Pfeifer-Weg, Theresanium)
                //TODO Bei Anreise Inhaltsverzeichnis wie in Kiel
            ])
    }
};
export default page;
